<template>
  <Breadcrumbs main="Dashboard" title="Active Stations"/>

  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-body">


            <div class="card-body">
              <div class="row align-items-center p-3" style="background-color: #f8f9fa; border-radius: 8px;">
                <div class="col-md-3">
                  <label class="form-label fw-bold">Export Format:</label>
                </div>
                <div class="col-md-6">
                  <div class="d-flex gap-4">
                    <div class="form-check">
                      <input
                          class="form-check-input"
                          type="radio"
                          id="xlsx"
                          value="xlsx"
                          v-model="exportFileFormat"
                          name="exportFileFormat"
                      >
                      <label class="form-check-label" for="xlsx">
                        Excel/xlsx
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                          class="form-check-input"
                          type="radio"
                          id="csv"
                          value="csv"
                          v-model="exportFileFormat"
                          name="exportFileFormat"
                      >
                      <label class="form-check-label" for="csv">
                        CSV
                      </label>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <button
                      @click="handleExport"
                      class="btn btn-success"
                      :disabled="isExportStarted"
                  >
                    <i class="fa fa-download me-2"></i>
                    {{ isExportStarted ? 'Downloading...' : 'Download' }}
                  </button>
                </div>
              </div>


              <div v-if="isExportStarted" class="loading-indicator m-t-25">
                <h6 class="sub-title mb-0 text-center fade show">
                <span class="loading-text">
                  &#128663; Rounding up all those stations... give us a moment to herd them into a file! &#128663;
                </span>
                </h6>
                <div class="loader-box">
                  <div class="loader-17"></div>
                </div>
              </div>

              <!-- Status Messages -->
              <div v-if="exportActiveStations.errorMessage" class="alert alert-danger mt-3">
                {{ exportActiveStations.errorMessage }}
              </div>
              <div v-if="exportActiveStations.successMessage" class="alert alert-success mt-3">
                {{ exportActiveStations.successMessage }}
              </div>
              <div v-if="exportActiveStations.infoMessage" class="alert alert-info mt-3">
                {{ exportActiveStations.infoMessage }}
              </div>

            </div>
          </div>
        </div>



        <!-- card 3 start -->
        <div class="card card-absolute m-t-25">
          <div class="card-header bg-info">
            <h5 class="text-white">Important Note</h5>
          </div>
          <div class="card-body">
            <div class="">
              <p>
                Download active petrol stations data in your preferred format (CSV or Excel). This export contains our complete database of ryd-supported petrol stations with their detailed location information.
              </p>
              <p class="mb-2">The exported file contains the following data points for each station:</p>
              <ul class="list-unstyled ms-3 mb-3">
                <li><strong>Station_brand</strong> - The official brand name of the petrol station</li>
                <li><strong>Latitude</strong> - Geographic coordinate specifying the north-south position</li>
                <li><strong>Longitude</strong> - Geographic coordinate specifying the east-west position</li>
                <li><strong>Street</strong> - Street name of the station's location</li>
                <li><strong>House_number</strong> - Building number on the street</li>
                <li><strong>City</strong> - Municipality where the station is located</li>
                <li><strong>Zip_code</strong> - Postal code of the station's address</li>
                <li><strong>Country_code</strong> - Two-letter ISO country code (e.g., DE, AT, NL)</li>
              </ul>
              <p class="alert alert-primary outline alert-dismissible fade show d-flex">
                This export currently includes traditional petrol stations only.
                Electric vehicle (EV) charging stations are not included in this dataset.
              </p>
            </div>
          </div>
        </div>
        <!-- card 3 end -->
      </div>
    </div>
  </div>
</template>

<script>
import { getUserAddDataFromLocalStorage } from "@/utils/userUtils";
import {
  scrollToCard,
  scrollToTop
} from "@/utils/anchorUtils";
import axios from "axios";
import {
  PARTNER_PERFORMANCE_GUARDIAN_API_SERVER_KAY,
  PARTNER_PERFORMANCE_GUARDIAN_API_SERVER_URL
} from "@/constants/constants";
import {getFormattedDateTime, toLocalTimeString} from "@/utils/dateUtils";

export default {
  name: "ActiveStations",
  keywords: [
    "active stations",
    "export stations",
    "download stations",
    "petrol stations",
    "fuel stations",
    "rydpay stations",
    "partner stations",
    "active petrol stations",
    "station export",
    "download active stations",
    "csv export",
    "excel export",
    "station list",
    "partner data export",
    "fuel station data",
    "download fuel stations",
    "data export",
    "station insights",
    "partner stations download",
    "stations overview"
  ],

  data() {
    return {
      loggedInUserGroup: '',
      isExportStarted: false,
      exportFileFormat: 'xlsx',
      exportActiveStations: {
        file: "",
        rowCount: 0,
        errorMessage: null,
        successMessage: null,
        infoMessage: null
      }
    };
  },

  mounted() {
    const { userGroup } = getUserAddDataFromLocalStorage();
    this.loggedInUserGroup = userGroup;
  },

  methods: {
    scrollToCard,
    scrollToTop,
    getFormattedDateTime,

    async handleExport() {
      this.isExportStarted = true;
      this.exportActiveStations = {
        file: "",
        rowCount: 0,
        errorMessage: null,
        successMessage: null,
        infoMessage: null
      };

      try {
        const response = await axios.get(`${PARTNER_PERFORMANCE_GUARDIAN_API_SERVER_URL}/active-gas-stations`, {
          params: {
            format: this.exportFileFormat
          },
          headers: {
            'x-txn-auth-token': PARTNER_PERFORMANCE_GUARDIAN_API_SERVER_KAY,
            'uid': this.loggedInUserGroup
          }
        });

        if (response.status === 200 && response.data) {
          const { file, rowCount } = response.data;

          if (rowCount === 0) {
            this.exportActiveStations.infoMessage = "No active stations could be found.";
            return;
          }

          // Convert base64 to blob
          const byteCharacters = atob(file);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);

          const contentType = this.exportFileFormat === 'csv'
              ? 'text/csv'
              : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

          const blob = new Blob([byteArray], { type: contentType });

          // Create download link
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          const fileName = `active_petrol_stations_${getFormattedDateTime()}.${this.exportFileFormat}`;

          link.href = url;
          link.download = fileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);

          this.exportActiveStations.successMessage = `Successfully exported ${rowCount.toLocaleString()} stations to ${fileName}`;
        } else {
          throw new Error('Invalid response from server');
        }
      } catch (error) {
        console.error('Error exporting active stations:', error);
        this.exportActiveStations.errorMessage = "A technical error occurred while generating the export.";
      } finally {
        this.isExportStarted = false;
      }
    }
  }
};
</script>

<style scoped>

.gap-4 {
  gap: 1rem;
}

.loading-text {
  display: inline-block;
  animation: slideInRight 1s ease-in-out, pulse 2s infinite;
}

@keyframes slideInRight {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
</style>