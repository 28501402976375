<template>
  <Breadcrumbs main="SDK Documentation" title="Changelog"/>

  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-body text-center p-5">
            <!-- Icon and Title -->
            <div class="mb-4">
              <i class="icofont icofont-gear-alt fa-5x text-primary"></i>
            </div>
            <h2 class="mb-4">Documentation Coming Soon</h2>

            <!-- Progress Indicator -->
            <div class="progress mb-4" style="height: 10px;">
              <div class="progress-bar progress-bar-striped progress-bar-animated bg-primary"
                   role="progressbar"
                   style="width: 75%"
                   aria-valuenow="75"
                   aria-valuemin="0"
                   aria-valuemax="100">
              </div>
            </div>

            <!-- Main Message -->
            <p class="lead mb-4">
              This documentation is currently being developed and can be delivered quickly upon request.
            </p>

            <!-- Contact Info -->
            <div class="alert alert-light d-inline-block">
              <p class="mb-0">
                <i class="icofont icofont-support text-primary"></i>
                For immediate assistance, please contact our support team.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ComingSoon',
  props: {
    title: {
      type: String,
      default: 'Coming Soon'
    }
  }
}
</script>

<style scoped>
.card {
  border-radius: 15px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
}

.progress {
  border-radius: 15px;
  background-color: #e9ecef;
}
</style>