<template>
  <Breadcrumbs main="Guides: Fuelling" title="Fuelling Test Scenarios" />

  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">


        <div class="card card-absolute">
          <div class="card-header bg-primary">
            <h5 class="text-white">Overview</h5>
          </div>
          <div class="card-body">
            <div>
              <p>
                To facilitate your testing process, we provide a Postman collection containing all the test
                scenarios mentioned below. You can download the collection and import it into your Postman
                application to start testing immediately.
              </p>
              <p>
                There are 2 Postman collections. The first one has test cases with the deprecated <code>/acquire</code> API. 
                The second one has test cases with <code>autoAcquire: true</code> on order creation and automatically triggers acquire.
              </p>
            </div>
            <br/>
            <div>
              <b>Select Collection:</b>
               <div class="col-sm-12 col-md-12 d-flex justify-content-center">
                  <select v-model="selectedApi" @change="loadSwaggerUI" class="m-r-20 form-control border border-primary min-width-max-content">
                    <option value="withoutAutoAcquire">Test Cases (with deprecated acquire)</option>
                    <option value="withAutoAcquire">Test Cases (autoAcquire: true)</option>
                  </select>
                  <button type="button" class="m-l-20 btn btn-primary" @click="downloadJsonFuelling">Download Ryd APIs Partner Postman Collection</button>
              </div>
            </div>

            <div v-if="isLoadingPartnerConfigs" class="loading-indicator">
              <h6 class="sub-title mb-0 text-center">Hang tight! We're whipping up your Postman collection!</h6>
              <div class="loader-box">
                <div class="loader-17"></div>
              </div>
            </div>

          </div>
        </div>

        <br />


        <div class="card card-absolute">
          <div class="card-header bg-success">
            <h5 class="text-white">How to Use the Postman Collection</h5>
          </div>
          <div class="card-body m-t-20">
            <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>Importing the Collection:</h5>
            <ul class="list-group">
              <li class="list-group-item">After downloading, open Postman and import the collection by
                selecting 'Import' and choosing the downloaded file.</li>
            </ul>
            <br />

            <div v-if="!tt4ApiTokensData.tt4ApiTokenStaging">
              <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>Setting Up Collection Variables:
              </h5>
              <ul class="list-group">
                <li class="list-group-item">
                  Set the <code>xTxnAuthToken</code> collection variable with your API key for the staging environment.
                  The Postman collection includes a temporary auth token; please replace it with your own.
                </li>
                <li class="list-group-item">
                  Additionally, update the <code>xTxnInitiatedByPartner</code> collection variable with your partner ID at
                  Ryd, which is <code>{{ loggedInUserGroup }}</code>.
                </li>
              </ul>
              <br/>
            </div>

            <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>Executing Test Scenarios: </h5>
            <ul class="list-group">
              <li class="list-group-item">Select a test case from the collection and hit 'Send' to execute the
                API requests. Observe the responses and validate them against the expected behavior outlined
                on this page.</li>
              <li class="list-group-item">The entire collection can be executed in Postman, which includes
                specific test validations for each request and response.</li>
            </ul>
            <br />

          </div>
        </div>


        <div class="card card-absolute">
          <div class="card-header bg-info">
            <h5>Test Case Index</h5>
          </div>
          <div class="card-body">
            <div class="alert alert-info outline" role="alert">
              <p>Select a test case to directly access its specific details.</p>
            </div>
            <FuelingWithAutoAcquireTestCasesList v-if="selectedApi === 'withAutoAcquire'" @scrollToCard2="scrollToCard2"/>
            <FuelingTestCasesList v-else @scrollToCard2="scrollToCard2"/>
          </div>
        </div>
        <FuelingWithAutoAcquireTestCases v-if="selectedApi === 'withAutoAcquire'"/>
        <FuelingTestCases v-else/>
      </div>
    </div>
  </div>
</template>


<script>

import {getUserAddDataFromLocalStorage} from "@/utils/userUtils";
import axios from "axios";
import {RPP_API_SERVER_URL} from "@/constants/constants";
import PostmanCollectionMixin from '@/mixins/PostmanCollectionMixin';
import FuelingTestCasesList from './test-cases/FuelingTestCasesList.vue'
import FuelingTestCases from "./test-cases/FuelingTestCases.vue";
import FuelingWithAutoAcquireTestCases from "./test-cases/FuelingWithAutoAcquireTestCases.vue";
import FuelingWithAutoAcquireTestCasesList from "./test-cases/FuelingWithAutoAcquireTestCasesList.vue";


export default {
  name: "FuellingTestScenarios",
  components: {
    FuelingTestCasesList,
    FuelingTestCases,
    FuelingWithAutoAcquireTestCases,
    FuelingWithAutoAcquireTestCasesList
  },
  mixins: [PostmanCollectionMixin],
  keywords: [
    'Fuelling', 'Top up', 'Testing Scenarios', 'Postman Collection', 'Happy Paths', 'Error Cases',
    'API Testing', 'Test Cases', 'API Scenarios', 'Test Documentation',
    'API Validation', 'API Testing Guide', 'API Test Examples', 'API Test Setup',
    'API Error Handling', 'Postman Tests', 'API Debugging', 'API QA',
    'Test Automation', 'Manual Testing', 'Integration Testing', 'Functional Testing',
    'Performance Testing', 'Test Scripts', 'Test Suite', 'API Workflow Testing',
    'Error Scenarios', 'Positive Test Cases', 'Negative Test Cases', 'Boundary Testing',
    'Edge Case Testing', 'API Test Plan', 'Testing Framework', 'Test Data',
    'API Response Validation', 'API Request Testing', 'Mock Server', 'Mock API',
    'API Simulation', 'API Mocking', 'Postman Setup', 'Postman Documentation',
    'Postman Collection Guide', 'API Scenario Examples', 'Testing Best Practices',
    'API Test Cases', 'Error Handling Scenarios', 'API Coverage', 'API Test Report',
    'Test Results', 'Test Execution', 'API Test Automation', 'API Regression Testing',
    'Postman Scripts', 'API Debugging Guide', 'API Test Checklist', 'End-to-End Testing',
    'Test Environment', 'Testing Tools', 'Postman Environment', 'Test Strategy',
    'Test Plan', 'API Validation Scenarios', 'Request Testing', 'Response Testing',
    'Test Documentation Guide', 'Test Scenarios Overview', 'API Testing Examples'
  ],
  data() {
    return {
      loggedInUserGroup: '',
      tt4ApiTokensData: {},
      isLoadingPartnerConfigs: false,
      userGroup: '',
      selectedApi: 'withoutAutoAcquire'
    }
  },
  created() {
    this.fetchPartnerConfigs();
  },
  methods: {
    downloadJsonFuelling() {
      if (this.selectedApi === 'withoutAutoAcquire') {
        return this.downloadJson('ryd-apis-partner.postman_collection-v-2023-12.json', 'fuelling');
      }
      return this.downloadJson('rydpay-api-fueling-auto-acquire.postman_collection.json', 'fuelling');
    },
    scrollToCard() {
      const hash = this.$route.hash;
      const offset = 75; // Height of your fixed header

      if (hash) {
        const element = document.querySelector(hash);

        if (element) {
          const elementPosition = element.getBoundingClientRect().top + window.scrollY;
          window.scrollTo({ top: elementPosition - offset, behavior: 'smooth' });
        }
      }
    },
    scrollToCard2(cardId) {
      const element = document.getElementById(cardId);
      const offset = 70; // Adjust this to the height of your fixed header

      if (element) {
        const elementPosition = element.getBoundingClientRect().top + window.scrollY;
        window.scrollTo({ top: elementPosition - offset, behavior: 'smooth' });
      }
    }
  },
  mounted() {
    this.scrollToCard();
  },
  watch: {
    '$route.hash': function () {
      this.scrollToCard();
    }
  }
}
</script>

<style>

</style>