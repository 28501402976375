<template>
  <ul class="list-group list-group-flush">
    <li class="list-group-item">
      <a
        @click.prevent="scrollToCard2('tc101')"
        class="text-primary text-decoration-none cursor-pointer"
        >TC 101: Postpay - Customer Selects a Pump, Doesn't Pick Up Nozzle</a
      >
    </li>
    <li class="list-group-item">
      <a
        @click.prevent="scrollToCard2('tc102')"
        class="text-primary text-decoration-none cursor-pointer"
      >
        TC 102: Postpay - Successful Transaction
      </a>
    </li>
    <li class="list-group-item">
      <a
        @click.prevent="scrollToCard2('tc103')"
        class="text-primary text-decoration-none cursor-pointer"
      >
        TC 103: Postpay - Transaction Rejection
      </a>
    </li>
    <li class="list-group-item">
      <a
        @click.prevent="scrollToCard2('tc104')"
        class="text-primary text-decoration-none cursor-pointer"
      >
        TC 104: Postpay - Pump Disabled Or In Use
      </a>
    </li>
    <li class="list-group-item">
      <a
        @click.prevent="scrollToCard2('tc201')"
        class="text-primary text-decoration-none cursor-pointer"
      >
        TC 201: Prepay - Successful Transaction
      </a>
    </li>
    <li class="list-group-item">
      <a
        @click.prevent="scrollToCard2('tc202')"
        class="text-primary text-decoration-none cursor-pointer"
      >
        TC 202: Prepay - Zero Transaction
      </a>
    </li>
    <li class="list-group-item">
      <a
        @click.prevent="scrollToCard2('tc203')"
        class="text-primary text-decoration-none cursor-pointer"
      >
        TC 203: Prepay - Transaction Rejection
      </a>
    </li>
    <li class="list-group-item">
      <a
        @click.prevent="scrollToCard2('tc204')"
        class="text-primary text-decoration-none cursor-pointer"
      >
        TC 204: Prepay - Pump Disabled Or In Use
      </a>
    </li>
    <li class="list-group-item">
      <a
        @click.prevent="scrollToCard2('tc205')"
        class="text-primary text-decoration-none cursor-pointer"
      >
        TC 205: Prepay - Pump Overshoot
      </a>
    </li>
  </ul>
</template>

<script>
export default {
  name: "FuelingTestCasesList",
  methods: {
    scrollToCard2(cardId) {
      this.$emit("scrollToCard2", cardId);
    },
  },
};
</script>
