
import Default from '../pages/dashboard/default';
import Ecommerce from '../pages/dashboard/ecommerce';
import Online from '../pages/dashboard/online'
import Crypto from '../pages/dashboard/crypto'
import Social from '../pages/dashboard/social.vue'
// Widgets
import General from '../pages/widgets/general'
import Chart from '../pages/widgets/chart'
// pageLayout
import indexBoxed from '../pages/pageLayout/boxed/indexBoxed.vue'
import indexRTL from '../pages/pageLayout/RTL/indexRTL.vue'
import indexDarklayout from '../pages/pageLayout/darklayout/indexDarklayout.vue'
import indexFooterdark from '../pages/pageLayout/footerdark/indexFooterdark.vue'
import indexFooterfixed from '../pages/pageLayout/footerfixed/indexFooterfixed.vue'
import indexFooterlight from '../pages/pageLayout/footerlight/indexFooterlight.vue'
import indexHidenavscroll from '../pages/pageLayout/hidenavscroll/indexHidenavscroll.vue'
// project
import createProject from '../pages/project/createproject/createProject.vue'
import indexList from '../pages/project/projectlist/indexList.vue'
/* File Manager  */
import FileManager from '../pages/filemanager';
// kanban
import Kanban from '../pages/kanban/indexKanban.vue'
// ecoomerce
import indexProduct from '../pages/ecommerce/product/indexProduct.vue'
import productPage from '../pages/ecommerce/productpage/productPage.vue'
import productList from '../pages/ecommerce/list/productList.vue'
import paymentDetails from '../pages/ecommerce/payment/details/paymentDetails.vue'
import orderHistory from '../pages/ecommerce/order/orderHistory.vue'
import invoiceView from '../pages/ecommerce/invoice/invoiceView.vue'
import cartView from '../pages/ecommerce/cart/cartView.vue'
import wishlistView from '../pages/ecommerce/wishlist/wishlistView.vue'
import checkoutView from '../pages/ecommerce/checkout/checkoutView.vue'
import pricingView from '../pages/ecommerce/pricing/pricingView.vue'
// mail
import readMail from '../pages/email/readMail/readMail.vue'
import composeView from '../pages/email/compose/composeView.vue'
// chat
import chatApp from '../pages/chat/chatApp/chatApp.vue'
import videoChat from '../pages/chat/videoChat/videoChat.vue'
// user
import userProfile from '../pages/users/profile/userProfile.vue'
import userEdit from '../pages/users/edit/userEdit.vue'
import userCreate from '../pages/users/create/userCreate.vue'
import userList from '../pages/users/lists/userList.vue'

import PartnerAdministrationCreate from '../pages/partnerAdministration/create/partnerAdministrationCreate.vue'
import PartnerAdministrationList from '../pages/partnerAdministration/list/partnerAdministrationList.vue'


// forms
import formValidation from "../pages/forms/formValidetion/formValidation"
import base_Input from "../pages/forms/baseInput/base_Input"
import checkbox_radio from "../pages/forms/Checkbox&Radio/checkbox_radio"
import input_groups from "../pages/forms/InputGroup/input_groups"
import megaOptions from "../pages/forms/megaOptions/megaOptions"

// form widgets
import select2 from "../pages/formWidgets/select2/select2Page.vue"
import switch_From from "../pages/formWidgets/switch/switch_From"
import touchspin_Form from "../pages/formWidgets/touchspin/touchspin_Form"
import typeahead_Form from "../pages/formWidgets/typeahead/typeahead_Form"
import clipboard_Form from "../pages/formWidgets/clipboard/clipboard_Form"
import datepicker from "../pages/formWidgets/datepicker/datePicker.vue"

// form layput
import defaultforms from '../pages/formLayout/default/defaultFotrm.vue'
import form_wizard2 from "../pages/formLayout/formWizrd2/form_wizard.vue"
import form_wizard1 from '../pages/formLayout/formWizard1/form_wizard2.vue'
import form_wizard3 from "../pages/formLayout/formWizard3/form_wizard1.vue"

// uikits
import Scrollable from "../pages/advance/scollable/scrollable_advance.vue";
import Sweetalert from "../pages/advance/sweetalert/sweetAert.vue";
import Tree from "../pages/advance/tree/treeView.vue";
import Rating from "../pages/advance/rating/ratingView.vue";
import Pagination from "../pages/advance/pagination/pagination_advance.vue";
import Ribbons from "../pages/advance/ribbons/ribbons_advance.vue";
import Tour from "../pages/advance/tour/tourView.vue";

import Breadcrumb from "../pages/advance/breadcrumbs/breadCrumb.vue";

import Sticky from "../pages/advance/sticky/stickyView.vue";

import ImageCropper from "../pages/advance/image_cropper/cropper_advance.vue";
import Notify from '../pages/advance/notify/toaster_advance.vue'
import dropzone from '../pages/advance/dropzone/dropzoneView.vue';
import animatedModal from '../pages/advance/animatedModal/animatedModal.vue'
import owlCarousel from '../pages/advance/owlCarousel/owlCarousel.vue'
import Rangeslider from '../pages/advance/range/rangeView.vue'
import BasicCard from '../pages/advance/basicCard/basicCard.vue'
import CreativeCard from '../pages/advance/creativeCard/creative_card.vue'
import DraggableCard from '../pages/advance/draggableCard/draggableCard.vue'
import TabbedCard from '../pages/advance/tabbedCard/tabbed_card.vue'
import timeLine from '../pages/advance/timeLine/timeLine.vue'
/* FAQ */
import Faq from '../pages/faq/faqIndex.vue';
// uikits
import alert from '../pages/uikits/alert';
import Helperclasses from '../pages/uikits/helper_classes';
import Avatars from '../pages/uikits/avatars';
import Grid from '../pages/uikits/grid';
import Tagpills from '../pages/uikits/tag_pills';
import Typography from '../pages/uikits/typography';
import Progressbar from '../pages/uikits/progress_bar';
import Modal from '../pages/uikits/modal';
import Popover from '../pages/uikits/popover';
import Tooltip from '../pages/uikits/tooltip';
import Spinners from '../pages/uikits/loader';
import Dropdown from '../pages/uikits/dropdown';
import Accordion from '../pages/uikits/accordion';
import Boxshadow from '../pages/uikits/box_shadow';
import Lists from '../pages/uikits/lists';
import bootstraptabPage from "../pages/uikits/tabs/bootstraptab/bootstraptabPage.vue";
import linetabPage from "../pages/uikits/tabs/linetab/linetabPage.vue";
// animation
import animate from '../pages/animation/animate/animatePage.vue'
import aos from '../pages/animation/aos/aosPage.vue'
import scroll from '../pages/animation/scroll/scrollPage.vue'
import wow from '../pages/animation/wow/wowPage.vue'
import tilt from '../pages/animation/tilt.vue'
// builder
import form1 from '../pages/builder/formBuilder1.vue'
import form2 from '../pages/builder/formBuilder2.vue'
/* icons */
import Flag from '../pages/icons/flag';
import Ico from '../pages/icons/ico_icon';
import Themify from '../pages/icons/themify_icon';
import Fontawesome from '../pages/icons/font_awesome';
import FeatherIcon from '../pages/icons/feather_icon';
import WhetherIcon from '../pages/icons/weather';
/* Buttons */
import Default_buttons from '../pages/button/Default/default_button.vue';
import Flat_buttons from '../pages/button/Flat/flat_button.vue';
import Edge_buttons from '../pages/button/Edge/edge_button.vue';
import Raised_buttons from '../pages/button/Raised/raised_button.vue';
import Group_buttons from '../pages/button/ButtonGroup/button_group.vue';
/*charts*/
import google_chart from "../pages/charts/googleChart/google_chart"
import apex_chart from "../pages/charts/ApexChart/apex_chart"
import chartist_chart from "../pages/charts/chartist/chartist_chart.vue"
// knowledge
import knowledge from '../pages/Knowledgebase/index.vue'
import category_knowledge from '../pages/Knowledgebase/categoryKnowledge.vue'
import detail_knowledge from '../pages/Knowledgebase/detailsKnowledge.vue'
// error
import Error400 from '../pages/error/error400';
import Error401 from '../pages/error/error401';
import Error403 from '../pages/error/error403';
import Error404 from '../pages/error/error404';
import Error500 from '../pages/error/error500';
import Error503 from '../pages/error/error503';

/* Support Ticket */
import Support from '../pages/support/support.vue';

/* Editor */
import SimpleEditor from '../pages/editor/simple_editor';
import CkEditor from '../pages/editor/ckeditor.vue';
/* Maps */
import GoogleMaps from '../pages/maps/google_map.vue';
import VueLeaflet from '../pages/maps/vue_leaflet.vue';
// learning
import learning_list from "../pages/learning/learninglist/learning_list"
import coursedetailed from "../pages/learning/courseDetailed"
// job Search
import job_list from "../pages/job/job-list/job_list"
import job_details from "../pages/job/job_details/job_details"
import job_apply from "../pages/job/job_apply/job_apply"
import job_card from "../pages/job/job_card/cardJob.vue"
// blog
import blog_detail from "../pages/blog/blog-details/blog_detail"
import blog_single from "../pages/blog/blog-single/blog_single"
import blog_add from "../pages/blog/blog-add/addBlog.vue"
// GALLERY
import grid_gallery from "../pages/gallery/grid_gallery"
import grid_desc from "../pages/gallery/grid_desc"
import hover_gallery from "../pages/gallery/hover-gallery/hover_gallery"
import masonry_gallery from "../pages/gallery/masonry-gallery/masonry_gallery"
import masonary_desc from "../pages/gallery/masonary_desc.vue"
/* Authentication */
import LoginOne from '../pages/authentication/login_one';
import LoginTwo from '../pages/authentication/login_two';
import LoginValidate from '../pages/authentication/login_validation.vue';
import LoginTooltip from '../pages/authentication/login_tooltip.vue'
import LoginSweetalert from '../pages/authentication/login_sweetalert.vue'

import RegisterImage from '../pages/authentication/register_image';
import RegisterImage2 from '../pages/authentication/register_image2';
import Unlock from '../pages/authentication/unlock';
import ForgetPassword from '../pages/authentication/forget_password';
import ResetPassword from '../pages/authentication/reset_password';
import Maintenance from '../pages/authentication/maintenance.vue'
/* Auth */
import login from '../auth/login';
import Register from '../auth/register';
import SetPassword from '../auth/passwordSetup.vue';
// comingsoon
import ComingsoonImage from '../pages/comingsoon/comingsoon_image';
import ComingsoonSimple from '../pages/comingsoon/comingsoon_simple';
import ComingsoonVideo from '../pages/comingsoon/comingsoon_video';
// SAMPLE PAGE
import sample_page from "../pages/sample_page"
/* bootstrap tables */
import BootstrapBasic from "../pages/tables/bootstrapTable/basicTables/basic_tables.vue";
import tableComponent from "../pages/tables/bootstrapTable/stylingtable/styling_table.vue"
import DatatableBasic from "../pages/tables/dataTable/BasicInit/basic_Init.vue"
// searchPage
import serchIndex from "../pages/search/serchIndex.vue"
// bookmark
import Bookmark from "../pages/bookmark/bookMark.vue"
// contact
import contactView from "../pages/contact/contactView.vue"
// task
import Task from "../pages/task/indexPage.vue"
// calendar
import Calendar from "../pages/calenderView.vue"
// social app
import socialPage from "../pages/socialApp/socialApp.vue"
// todo
import Todo from "../pages/todo/index.vue"

// changelog
import PartnerPortalChangelog from "../pages/portalchangelog/PartnerPortalChangelog.vue";
import CommunityAndSupport from "../pages/communityandsupport/CommunityAndSupport.vue";
import AccountSettings from "../pages/accountsettings/AccountSettings.vue";


// integration guid
import DeeplinkRegistrationApiReference from "../pages/integrationguide/deeplinkingregistration/ApiReference.vue";
import DeeplinkRegistrationApiGettingStarted
  from "../pages/integrationguide/deeplinkingregistration/GettingStarted.vue";
import DeeplinkRegistrationChangelog from "../pages/integrationguide/deeplinkingregistration/Changelog.vue";
import DeeplinkRegistrationGuidesAndTutorials
  from "../pages/integrationguide/deeplinkingregistration/GuidesAndTutorials.vue";
import DeeplinkRegistrationFaqs from "../pages/integrationguide/deeplinkingregistration/Faqs.vue";


import RydPayApiGettingStarted from "../pages/integrationguide/rydpayapis/GettingStarted.vue";
import RydPayApiIntegrationPathways from "../pages/integrationguide/rydpayapis/IntegrationPathways.vue";

import RydPayApiFAQs from "../pages/integrationguide/rydpayapis/support-and-resources/Faqs.vue";
import RydPayApiTermsAndDefinitions from "../pages/integrationguide/rydpayapis/support-and-resources/TermsAndDefinitions.vue";
import RydPayApiReleaseNotesAndUpdates from "../pages/integrationguide/rydpayapis/ReleaseNotesAndUpdates.vue";
import RydPayApiTroubleshootingAndSupport from "../pages/integrationguide/rydpayapis/support-and-resources/TroubleshootingAndSupport.vue";
import RydPayWebhooks from "../pages/integrationguide/rydpayapis/guides-general/RydPayWebhooks.vue";
import FuellingSimulatedStations from "../pages/integrationguide/rydpayapis/guides-fuelling/FuellingSimulatedStations.vue";
import FuellingTestScenarios from "../pages/integrationguide/rydpayapis/guides-fuelling/FuellingTestScenarios.vue";
import CarWashTestScenarios from "../pages/integrationguide/rydpayapis/guides-car-wash/CarWashTestScenarios.vue";
import CarWashSimulatedStations from "../pages/integrationguide/rydpayapis/guides-car-wash/CarWashSimulatedStations.vue";
import EvChargingTestScenarios from "../pages/integrationguide/rydpayapis/guides-ev-charging/EvChargingTestScenarios.vue";
import EVChargingApiJourney from "../pages/integrationguide/rydpayapis/guides-ev-charging/EVChargingApiJourney.vue";

import EvChargingSimulatedStations from "../pages/integrationguide/rydpayapis/guides-ev-charging/EvChargingSimulatedStations.vue";
import EvPricing from "../pages/integrationguide/rydpayapis/guides-ev-charging/EvPricing.vue";
import EvInsightsAndStatistics from "../pages/integrationguide/rydpayapis/guides-ev-charging/EvInsightsAndStatistics.vue";


import RydPayApiApiFundamentals from "@/pages/integrationguide/rydpayapis/guides-general/ApiFundamentals.vue";
import RydPayApiOrderStateManagement from "@/pages/integrationguide/rydpayapis/guides-general/OrderStateManagement.vue";
import RydPayApiBestPractices from "@/pages/integrationguide/rydpayapis/guides-general/BestPractices.vue";

import RydPayApiPrepayAndPostpay from "@/pages/integrationguide/rydpayapis/guides-fuelling/PrepayAndPostpay.vue";

import TopupApiReference from "../pages/integrationguide/rydpayapis/ApiReference.vue";

// webapp integration
import WebappIntegrationGettingStarted from "../pages/integrationguide/webappIntegration/GettingStarted.vue";
import WebappIntegrationIntroduction from "../pages/integrationguide/webappIntegration/Introduction.vue";
import WebappIntegrationGuidesAndTutorials from "../pages/integrationguide/webappIntegration/GuidesAndTutorials.vue";
import WebappIntegrationFAQs from "../pages/integrationguide/webappIntegration/Faqs.vue";
import WebappIntegrationTermsAndDefinitions from "../pages/integrationguide/webappIntegration/TermsAndDefinitions.vue";
import WebappIntegrationTesting from "../pages/integrationguide/webappIntegration/Testing.vue";
import WebappIntegrationTroubleshootingAndSupport
  from "../pages/integrationguide/webappIntegration/TroubleshootingAndSupport.vue";
import WebappIntegrationChangelog from "../pages/integrationguide/webappIntegration/Changelog.vue";
import WebappIntegrationTopupFlowSteps from "../pages/integrationguide/webappIntegration/TopupFlowSteps.vue";

import IntegrationOverview from "../pages/integrationguide/integrationOverview/IntegrationOverview.vue";

// troubeshooting
import TroubeshootingOrderDetails from "../pages/troubleshooting/orderdetails/OrderDetails.vue";
import AccountDetails from "../pages/troubleshooting/accountDetails/AccountDetails.vue";
import OrderReportsByPoi from "../pages/troubleshooting/orderReports/OrderReportsByPoi.vue";


import DashboardBasic from "../pages/dashboard/basic/Basic.vue";
import DashboardRequestStats from "@/pages/dashboard/apiRequestsAnalytics/ApiRequestsAnalytics.vue";
import PpTokenUsage from "../pages/troubleshooting/ppTokenUsage/PpTokenUserage.vue";
import MissingVoiceApiCalls from "../pages/troubleshooting/missingVoiceApiCalls/MissingVoiceApiCalls.vue";
import OrderStats from "@/pages/dashboard/orderInsightsAnalytics/OrderInsightsAnalytics.vue";

// platform status
import PlatformStatusCurrentStatus from "../pages/platformStatus/currentStatus/PlatformStatusCurrentStatus.vue";
import PlatformStatusPastIncidents from "../pages/platformStatus/pastIncidents/PlatformStatusPastIncidents.vue";
import PlatformStatusScheduledMaintenance
  from "../pages/platformStatus/scheduledMaintenance/PlatformStatusScheduledMaintenance.vue";
import PlatformStatusSystemMetrics from "../pages/platformStatus/systemMetrics/PlatformStatusSystemMetrics.vue";

// Guardian System and Alerts
import GuardianChecks from "@/pages/partnerGuardianAndAlerts/guardianChecks/GuardianChecks.vue";
import CashierSystemConfigurationsList
  from "@/pages/partnerGuardianAndAlerts/cashierSystemConfigurations/CashierSystemConfigurationsList.vue";
import CashierSystemConfigurationsCreation
  from "@/pages/partnerGuardianAndAlerts/cashierSystemConfigurations/CashierSystemConfigurationsCreation.vue";
import CashierSystemConfigurationsView
  from "@/pages/partnerGuardianAndAlerts/cashierSystemConfigurations/CashierSystemConfigurationsView.vue";

import MocPartnerConfigurationsList
  from "@/pages/partnerGuardianAndAlerts/mocPartnerConfigurations/MocPartnerConfigurationsList.vue";
import MocPartnerConfigurationsCreation
  from "@/pages/partnerGuardianAndAlerts/mocPartnerConfigurations/MocPartnerConfigurationsCreation.vue";
import MocPartnerConfigurationsView
  from "@/pages/partnerGuardianAndAlerts/mocPartnerConfigurations/MocPartnerConfigurationsView.vue";
import MocPartnerConfigurationsEdit
  from "@/pages/partnerGuardianAndAlerts/mocPartnerConfigurations/MocPartnerConfigurationsEdit.vue";

import GuardianCheckAlertsList from "@/pages/partnerGuardianAndAlerts/alerts/PartnerGuardianAlertsList.vue";
import GuardianCheckAlertsEdit from "@/pages/partnerGuardianAndAlerts/alerts/PartnerGuardianAlertsEdit.vue";

import StationOfflineStatusDashboard from "@/pages/partnerGuardianAndAlerts/guardianChecks/StationOfflineStatusDashboard.vue";
import GuardianCheckOfflineHistory from "@/pages/partnerGuardianAndAlerts/guardianChecks/GuardianCheckOfflineHistory.vue";
import GuardianCheckMapView from "@/pages/partnerGuardianAndAlerts/guardianChecks/GuardianCheckMapView.vue";


// accounting
import PartnerExchangeFilesQ8 from "../pages/accouting/partnerexchangefiles/PartnerExchangeFilesQ8.vue";
import CashierSystemConfigurationsEdit
  from "@/pages/partnerGuardianAndAlerts/cashierSystemConfigurations/CashierSystemConfigurationsEdit.vue";
import TestingAndSimulation from "@/pages/integrationguide/rydpayapis/guides-general/TestingAndSimulation.vue";

export var menuItems = {
  "data": [
    {
      "headTitle1": "General",
      "headTitle2": "Dashboards,Widgets & Layout.",
      "type": "headtitle"
    },
    {
      "title": "Dashboard",
      "icon": "stroke-home",
      "iconf": "fill-home",
      "type": "sub",
      "active": false,
      "children": [
        {
          "path": "/",
          "title": "Welcome",
          "type": "link",
          "component": Default,
          "active": false
        },
        {
          "path": "/dashboard/api-requests-analytics",
          "title": "API Requests Analytics",
          "type": "link",
          "component": DashboardRequestStats,
          "active": false
        },
        {
          "path": "/dashboard/order-insights-analytics",
          "title": "Order Insights & Analytics",
          "type": "link",
          "component": OrderStats,
          "active": false
        },
        {
          "path": "/dashboard/active-stations",
          "title": "Active Stations",
          "type": "link",
          "active": false
        }
      ]
    },
    {
      "title": "Troubleshooting",
      "icon": "stroke-search",
      "iconf": "fill-search",
      "type": "sub",
      "active": false,
      "children": [
        {
          "path": "/troubleshooting/order-details",
          "title": "Order Details",
          "type": "link",
          "component": TroubeshootingOrderDetails,
          "active": false
        },
        {
          "path": "/troubleshooting/token-usage",
          "title": "Token Usage",
          "type": "link",
          "component": PpTokenUsage,
          "active": false,
          "requiredUserGroup": [
            "ryd",
            "parkopedia"
          ],
        },
        {
          "path": "/troubleshooting/missing-invoice-api-calls",
          "title": "Missing Invoice API Calls",
          "type": "link",
          "component": MissingVoiceApiCalls,
          "active": false,
          "requiredUserGroup": [
            "ryd",
            "parkopedia"
          ],
        },
        {
          "path": "/troubleshooting/account-activities",
          "title": "Account Activities",
          "component": AccountDetails,
          "type": "link",
          "active": false,
          "requiredUserGroup": [
            "ryd"
          ],
        },
        {
          "path": "/troubleshooting/order-data-by-location",
          "title": "Order Data by Location",
          "component": OrderReportsByPoi,
          "type": "link",
          "active": false,
          "requiredUserGroup": [
            "ryd",
            "miles"
          ],
        },
      ]
    },
    {
      "headTitle1": "Integration Guide",
      "headTitle2": "How to integrate ryd",
      "type": "headtitle"
    },
    {
      "title": "Integration Overview",
      "icon": "stroke-widget",
      "iconf": "fill-widget",
      "type": "sub",
      "active": false,
      "children": [
        {
          "path": "/integration-overview",
          "title": "Integration Overview",
          "component": IntegrationOverview,
          "type": "link",
          "active": false
        }
      ]
    },
    {
      "title": "rydpay APIs",
      "icon": "api-integration-widget",
      "iconf": "api-integration-widget",
      "type": "sub",
      "active": false,
      "children": [
        {
          "path": "/integration-guide/rydpay-apis/getting-started",
          "title": "Getting Started",
          "component": RydPayApiGettingStarted,
          "type": "link",
          "active": false
        },
        {
          "path": "/integration-guide/rydpay-apis/integration-pathways",
          "title": "Integration Pathways",
          "component": RydPayApiIntegrationPathways,
          "type": "link",
          "active": false
        },
        {
          "title": "Guides: General",
          "type": "sub",
          "active": false,
          "children": [
            {
              "path": "/integration-guide/rydpay-apis/api-fundamentals",
              "title": "API Fundamentals",
              "component": RydPayApiApiFundamentals,
              "type": "link",
              "active": false
            },
            {
              "path": "/integration-guide/rydpay-apis/order-state-management",
              "title": "Order State Management",
              "component": RydPayApiOrderStateManagement,
              "type": "link",
              "active": false
            },
            {
              "path": "/integration-guide/rydpay-apis/best-practices",
              "title": "Best Practices",
              "component": RydPayApiBestPractices,
              "type": "link",
              "active": false
            },
            {
              "path": "/integration-guide/rydpay-apis/webhooks",
              "title": "Webhooks",
              "component": RydPayWebhooks,
              "type": "link",
              "active": false
            },
            {
              "path": "/integration-guide/rydpay-apis/testing-and-simulation",
              "title": "Testing & Simulation",
              "component": TestingAndSimulation,
              "type": "link",
              "active": false
            }
          ]
        },
        {
          "title": "Guides: Fuelling",
          "type": "sub",
          "active": false,
          "children": [
            {
              "path": "/integration-guide/rydpay-apis/guides-fuelling/prepay-and-postpay",
              "title": "Prepay vs Postpay",
              "component": RydPayApiPrepayAndPostpay,
              "type": "link",
              "active": false
            },
            {
              "path": "/integration-guide/rydpay-apis/testing-and-simulation/fuelling-test-scenarios",
              "title": "Test Scenarios",
              "component": FuellingTestScenarios,
              "type": "link",
              "active": false
            },
            {
              "path": "/integration-guide/rydpay-apis/testing-and-simulation/fuelling-simulated-stations",
              "title": "Simulated Stations",
              "component": FuellingSimulatedStations,
              "type": "link",
              "active": false
            },
          ]
        },
        {
          "title": "Guides: Car Wash",
          "type": "sub",
          "active": false,
          "children": [
            {
              "path": "/integration-guide/rydpay-apis/testing-and-simulation/car-wash-test-scenarios",
              "title": "Test Scenarios",
              "component": CarWashTestScenarios,
              "type": "link",
              "active": false
            },
            {
              "path": "/integration-guide/rydpay-apis/testing-and-simulation/car-wash-simulated-stations",
              "title": "Simulated Stations",
              "component": CarWashSimulatedStations,
              "type": "link",
              "active": false
            },
          ]
        },
        {
          "title": "Guides: EV Charging",
          "type": "sub",
          "active": false,
          "children": [
            {
              "path": "/integration-guide/rydpay-apis/guides-ev-charging/ev-insights-and-statistics",
              "title": "EV Insights & Statistics",
              "component": EvInsightsAndStatistics,
              "type": "link",
              "active": false
            },
            {
              "path": "/integration-guide/rydpay-apis/guides-ev-charging/api-journey",
              "title": "API Journey",
              "component": EVChargingApiJourney,
              "type": "link",
              "active": false
            },
            {
              "path": "/integration-guide/rydpay-apis/guides-ev-charging/ev-pricing",
              "title": "EV Pricing",
              "component": EvPricing,
              "type": "link",
              "active": false
            },
            {
              "path": "/integration-guide/rydpay-apis/testing-and-simulation/ev-charging-test-scenarios",
              "title": "Test Scenarios",
              "component": EvChargingTestScenarios,
              "type": "link",
              "active": false
            },
            {
              "path": "/integration-guide/rydpay-apis/testing-and-simulation/ev-charging-simulated-stations",
              "title": "Simulated Stations",
              "component": EvChargingSimulatedStations,
              "type": "link",
              "active": false
            }
          ]
        },
        {
          "path": "/integration-guide/rydpay-apis/api-reference",
          "title": "ryd.pay API Reference",
          "component": TopupApiReference,
          "type": "link",
          "active": false
        },
        {
          "title": "Support & Resources",
          "type": "sub",
          "active": false,
          "children": [
            {
              "path": "/integration-guide/rydpay-apis/troubleshooting-and-support",
              "title": "Troubleshooting & Support",
              "component": RydPayApiTroubleshootingAndSupport,
              "type": "link",
              "active": false
            },
            {
              "path": "/integration-guide/rydpay-apis/terms-and-definitions",
              "title": "Terms & Definitions",
              "component": RydPayApiTermsAndDefinitions,
              "type": "link",
              "active": false
            },
            {
              "path": "/integration-guide/rydpay-apis/faqs",
              "title": "FAQs",
              "component": RydPayApiFAQs,
              "type": "link",
              "active": false
            }
          ]
        },
        {
          "path": "/integration-guide/rydpay-apis/release-notes-and-updates",
          "title": "Release Notes & Updates",
          "component": RydPayApiReleaseNotesAndUpdates,
          "type": "link",
          "active": false
        },

      ]
    },


    {
      "title": "Webapp Integration",
      "icon": "webapp-integration-widget",
      "iconf": "webapp-integration-widget",
      "type": "sub",
      "active": false,
      "children": [
        {
          "path": "/integration-guide/webapp-integration/introduction",
          "title": "Introduction",
          "component": WebappIntegrationIntroduction,
          "type": "link",
          "active": false
        },
        {
          "path": "/integration-guide/webapp-integration/getting-started",
          "title": "Getting Started",
          "component": WebappIntegrationGettingStarted,
          "type": "link",
          "active": false
        },
        {
          "path": "/integration-guide/webapp-integration/topup-flow-steps",
          "title": "Topup Flow Steps",
          "component": WebappIntegrationTopupFlowSteps,
          "type": "link",
          "active": false
        },
        {
          "path": "/integration-guide/webapp-integration/guides-and-tutorials",
          "title": "Guides & Tutorials",
          "component": WebappIntegrationGuidesAndTutorials,
          "type": "link",
          "active": false
        },
        {
          "path": "/integration-guide/webapp-integration/testing",
          "title": "Testing",
          "component": WebappIntegrationTesting,
          "type": "link",
          "active": false
        },
        {
          "path": "/integration-guide/webapp-integration/troubleshooting-and-support",
          "title": "Troubleshooting & Support",
          "component": WebappIntegrationTroubleshootingAndSupport,
          "type": "link",
          "active": false
        },
        {
          "path": "/integration-guide/webapp-integration/terms-and-definitions",
          "title": "Terms & Definitions",
          "component": WebappIntegrationTermsAndDefinitions,
          "type": "link",
          "active": false
        },
        {
          "path": "/integration-guide/webapp-integration/faqs",
          "title": "FAQs",
          "component": WebappIntegrationFAQs,
          "type": "link",
          "active": false
        },
        {
          "path": "/integration-guide/webapp-integration/changelog",
          "title": "Changelog",
          "component": WebappIntegrationChangelog,
          "type": "link",
          "active": false
        }
      ]
    },
    {
      "title": "Registration Deeplink",
      "icon": "stroke-widget",
      "iconf": "fill-widget",
      "type": "sub",
      "active": false,

      "children": [
        {
          "path": "/integration-guide/deeplinking-registration/getting-started",
          "title": "Getting Started",
          "component": DeeplinkRegistrationApiGettingStarted,
          "type": "link",
          "active": false
        },
        {
          "path": "/integration-guide/deeplinking-registration/api-reference",
          "title": "API Reference",
          "component": DeeplinkRegistrationApiReference,
          "type": "link",
          "active": false
        },
        {
          "path": "/integration-guide/deeplinking-registration/guides-and-tutorials",
          "title": "Guides & Tutorials",
          "component": DeeplinkRegistrationGuidesAndTutorials,
          "type": "link",
          "active": false
        },
        {
          "path": "/integration-guide/deeplinking-registration/faqs",
          "title": "FAQs",
          "component": DeeplinkRegistrationFaqs,
          "type": "link",
          "active": false
        },
        {
          "path": "/integration-guide/deeplinking-registration/changelog",
          "title": "Changelog",
          "component": DeeplinkRegistrationChangelog,
          "type": "link",
          "active": false
        }
      ]
    },
    {
      "title": "SDK Documentation",
      "icon": "stroke-widget",
      "iconf": "fill-widget",
      "type": "sub",
      "active": false,
      "children": [
        {
          "path": "/integration-guide/rydsdk/introduction",
          "title": "Introduction & Benefits",
          "type": "link",
          "active": false
        },
        {
          "path": "/integration-guide/rydsdk/getting-started",
          "title": "Getting Started",
          "type": "sub",
          "active": false,
          "children": [
            {
              "path": "/integration-guide/rydsdk/getting-started/installation",  // Full path needed
              "title": "Installation & Setup",
              "type": "link",
              "active": false
            },
            {
              "path": "/integration-guide/rydsdk/getting-started/configuration",  // Full path needed
              "title": "Basic Configuration",
              "type": "link",
              "active": false
            },
            {
              "path": "/integration-guide/rydsdk/getting-started/quickstart",  // Full path needed
              "title": "Quick Start Guides",
              "type": "link",
              "active": false
            }
          ]
        },
        {
          "path": "/integration-guide/rydsdk/features",
          "title": "Features",
          "type": "sub",
          "active": false,
          "children": [
            {
              "path": "/integration-guide/rydsdk/features/fueling",  // Full path needed
              "title": "Fueling Integration",
              "type": "link",
              "active": false
            },
            {
              "path": "/integration-guide/rydsdk/features/carwash",  // Full path needed
              "title": "Car Wash Integration",
              "type": "link",
              "active": false
            },
            {
              "path": "/integration-guide/rydsdk/features/charging",  // Full path needed
              "title": "EV Charging Integration",
              "type": "link",
              "active": false
            }
          ]
        },
        {
          "path": "/integration-guide/rydsdk/resources",
          "title": "Resources",
          "type": "sub",
          "active": false,
          "children": [
            {
              "path": "/integration-guide/rydsdk/resources/best-practices",  // Full path needed
              "title": "Best Practices",
              "type": "link",
              "active": false
            },
            {
              "path": "/integration-guide/rydsdk/resources/faq",  // Full path needed
              "title": "FAQ & Troubleshooting",
              "type": "link",
              "active": false
            },
            {
              "path": "/integration-guide/rydsdk/resources/changelog",  // Full path needed
              "title": "Changelog",
              "type": "link",
              "active": false
            }
          ]
        }
      ]
    },

    {
      "headTitle1": "Accounting",
      "type": "headtitle",
      "requiredRoles": [
        "accountingAdmin"
      ],
    },
    {
      "title": "Partner File Hub",
      "icon": "stroke-support-tickets",
      "iconf": "fill-support-tickets",
      "type": "sub",
      "active": false,
      "requiredRoles": [
        "accountingAdmin"
      ],
      "children": [
        {
          "path": "/accounting/partner-file-hub/q8",
          "title": "Q8 Reconciliation Process",
          "component": PartnerExchangeFilesQ8,
          "type": "link",
          "active": false
        },
      ]
    },
    {
      "headTitle1": "Platform Status",
      "headTitle2": "Ryd platform and system status",
      "type": "headtitle"
    },
    {
      "title": "Platform Status",
      "icon": "stroke-support-tickets",
      "iconf": "fill-support-tickets",
      "type": "sub",
      "active": false,
      "children": [
        {
          "path": "/platform-status/current-status",
          "title": "Current Status",
          "component": PlatformStatusCurrentStatus,
          "type": "link",
          "active": false
        },
        {
          "path": "/platform-status/scheduled-maintenance",
          "title": "Scheduled Maintenance",
          "component": PlatformStatusScheduledMaintenance,
          "type": "link",
          "active": false
        },
        {
          "path": "/platform-status/system-metrics",
          "title": "System Metrics",
          "component": PlatformStatusSystemMetrics,
          "type": "link",
          "active": false
        },
        {
          "path": "/platform-status/past-incidents",
          "title": "Past Incidents",
          "component": PlatformStatusPastIncidents,
          "type": "link",
          "active": false
        }
      ]
    },
    {
      "title": "Guardian System and Alerts",
      "icon": "stroke-support-tickets",
      "iconf": "fill-support-tickets",
      "type": "sub",
      "active": false,
      "requiredUserGroup": [
        "ryd"
      ],
      "children": [
        {
          "title": "Cashier System Config",
          "type": "sub",
          "active": false,
          "requiredUserGroup": [
            "ryd"
          ],
          "children": [
            {
              "path": "/guardian-system-and-alerts/cashier-system-configuration/create",
              "title": "Create",
              "component": CashierSystemConfigurationsCreation,
              "type": "link",
              "active": false,
              "requiredUserGroup": [
                "ryd"
              ]
            },
            {
              "path": "/guardian-system-and-alerts/cashier-system-configuration/edit",
              "title": "Edit",
              "component": CashierSystemConfigurationsEdit,
              "type": "link",
              "active": false,
              "requiredUserGroup": [
                "ryd"
              ],
            },
            {
              "path": "/guardian-system-and-alerts/cashier-system-configuration/view",
              "title": "View",
              "component": CashierSystemConfigurationsView,
              "type": "link",
              "active": false,
              "requiredUserGroup": [
                "ryd"
              ],
            },
            {
              "path": "/guardian-system-and-alerts/cashier-system-configuration/list",
              "title": "List",
              "component": CashierSystemConfigurationsList,
              "type": "link",
              "active": false,
              "requiredUserGroup": [
                "ryd"
              ],
            }
          ]
        },
        {
          "title": "MOC Partner Config",
          "type": "sub",
          "active": false,
          "requiredUserGroup": [
            "ryd"
          ],
          "children": [
            {
              "path": "/guardian-system-and-alerts/moc-partner-configuration/create",
              "title": "Create",
              "component": MocPartnerConfigurationsCreation,
              "type": "link",
              "active": false,
              "requiredUserGroup": [
                "ryd"
              ]
            },
            {
              "path": "/guardian-system-and-alerts/moc-partner-configuration/edit",
              "title": "Edit",
              "component": MocPartnerConfigurationsEdit,
              "type": "link",
              "active": false,
              "requiredUserGroup": [
                "ryd"
              ],
            },
            {
              "path": "/guardian-system-and-alerts/moc-partner-configuration/view",
              "title": "View",
              "component": MocPartnerConfigurationsView,
              "type": "link",
              "active": false,
              "requiredUserGroup": [
                "ryd"
              ],
            },
            {
              "path": "/guardian-system-and-alerts/moc-partner-configuration/list",
              "title": "List",
              "component": MocPartnerConfigurationsList,
              "type": "link",
              "active": false,
              "requiredUserGroup": [
                "ryd"
              ],
            }
          ]
        },
        {
          "title": "Alerts",
          "type": "sub",
          "active": false,
          "requiredUserGroup": [
            "ryd"
          ],
          "children": [
            {
              "path": "/guardian-system-and-alerts/alerts/edit",
              "title": "Edit",
              "component": GuardianCheckAlertsEdit,
              "type": "link",
              "active": false,
              "requiredUserGroup": [
                "ryd"
              ],
            },
            {
              "path": "/guardian-system-and-alerts/alerts/list",
              "title": "List",
              "component": GuardianCheckAlertsList,
              "type": "link",
              "active": false,
              "requiredUserGroup": [
                "ryd"
              ],
            }
          ]
        },
        {
          "title": "Guardian Checks",
          "type": "sub",
          "active": false,
          "requiredUserGroup": [
            "ryd"
          ],
          "children": [
            {
              "path": "/guardian-system-and-alerts/guardian-checks",
              "title": "Checks",
              "component": GuardianCheckAlertsEdit,
              "type": "link",
              "active": false,
              "requiredUserGroup": [
                "ryd"
              ],
            },
            {
              "path": "/guardian-system-and-alerts/station-offline-status-dashboard",
              "title": "Dashboard",
              "component": StationOfflineStatusDashboard,
              "type": "link",
              "active": false,
              "requiredUserGroup": [
                "ryd"
              ],
            },
            {
              "path": "/guardian-system-and-alerts/station-offline-history",
              "title": "Station Offline History",
              "component": GuardianCheckOfflineHistory,
              "type": "link",
              "active": false,
              "requiredUserGroup": [
                "ryd"
              ],
            },
            {
              "path": "/guardian-system-and-alerts/guardian-check-map-view",
              "title": "Check Map View",
              "component": GuardianCheckMapView,
              "type": "link",
              "active": false,
              "requiredUserGroup": [
                "ryd"
              ],
            }
          ]
        }
      ]
    },

    {
      "headTitle1": "Miscellaneous",
      "headTitle2": "Bouns pages & apps",
      "type": "headtitle"
    },
    {
      "title": "Users",
      "icon": "stroke-user",
      "iconf": "fill-user",
      "type": "sub",
      "active": false,
      "children": [
        {
          "path": "/users/profile",
          "title": "User Profile",
          "component": userProfile,
          "type": "link",
          "active": false
        },
        {
          "path": "/users/edit",
          "title": "User Edit",
          "component": userEdit,
          "type": "link",
          "active": false
        },
        {
          "path": "/users/list",
          "title": "User List",
          "component": userList,
          "type": "link",
          "active": false,
          "requiredRoles": [
            "userManagementAdmin"
          ]
        },
        {
          "path": "/users/create",
          "title": "User create",
          "component": userCreate,
          "type": "link",
          "active": false,
          "requiredRoles": [
            "userManagementAdmin"
          ]
        }
      ]
    },
    {
      "title": "Partner Administration",
      "icon": "stroke-user",
      "iconf": "fill-user",
      "type": "sub",
      "active": false,
      "requiredRoles": [
        "partnerAdmin"
      ],
      "children": [
        {
          "path": "/partner-administration/list",
          "title": "Partner List",
          "component": PartnerAdministrationList,
          "type": "link",
          "active": false
        },
        {
          "path": "/partner-administration/create",
          "title": "Partner Create",
          "component": PartnerAdministrationCreate,
          "type": "link",
          "active": false
        }
      ]
    },

    {
      "path": "/pages/community-and-feedback",
      "title": "Community & Feedback",
      "icon": "stroke-social",
      "iconf": "fill-social",
      "type": "link",
      "active": false
    },
    {
      "path": "/pages/account-settings",
      "title": "Account Settings",
      "component": AccountSettings,
      "icon": "stroke-sample-page",
      "iconf": "fill-sample-page",
      "type": "link",
      "active": "false"
    },
    {
      "path": "/pages/ryd-partner-portal-changelog",
      "title": "Portal Changelog",
      "component": PartnerPortalChangelog,
      "icon": "stroke-faq",
      "iconf": "fill-faq",
      "type": "link",
      "active": "false"
    },


  ]
}