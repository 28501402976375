<template>
  <!-- TC 401 -->
  <div class="card" id="ts401">
    <div class="card-header bg-primary">
      <h5>Scenario 401: Successful Charging</h5>
    </div>
    <div class="card-body">
      <h5>
        <i class="icofont icofont-hand-right font-primary">&nbsp;</i>Description
      </h5>
      <ul class="list-group">
        <li class="list-group-item">
          This test case validates the system's response when a customer
          successfully initiates and completes an EV charging session at a
          specified charging station.
        </li>
      </ul>
      <br />

      <h5>
        <i class="icofont icofont-hand-right font-primary">&nbsp;</i>Steps:
      </h5>
      <ul class="list-group">
        <li class="list-group-item font-primary">
          1. Select Charging Station:
          <ol class="list-group">
            <li class="list-group-item">
              The customer selects the charging station with the poiId
              <code>b989ce59-d976-411d-ba30-0d2e1f90baac</code> and selects the
              charging port <code>NLLMSE11700000*1</code>
            </li>
          </ol>
        </li>

        <li class="list-group-item font-primary">
          2. Authorize Payment Amount:
          <ol class="list-group">
            <li class="list-group-item">
              Optionally, the customer can select a maximum amount to be
              authorized for the charging session.
            </li>
            <li class="list-group-item">
              Alternatively, the Ryd partner may always authorize a fixed amount
              without giving the customer an option to choose an amount.
            </li>
            <li class="list-group-item">
              In this test scenario, the amount is 40 EURO.
            </li>
            <li class="list-group-item">
              Important:
              <a
                href="/integration-guide/rydpay-apis/terms-and-definitions#overshoot"
                >Overshooting</a
              >
              often occurs in EV charging because charging stations cannot stop
              at a reserved amount like fueling pumps.
            </li>
          </ol>
        </li>

        <li class="list-group-item font-primary">
          3. Authorize Payment:
          <ol class="list-group">
            <li class="list-group-item">
              The customer authorizes the payment.
            </li>
          </ol>
        </li>

        <li class="list-group-item font-primary">
          4. Start Charging
          <ol class="list-group">
            <li class="list-group-item">
              The customer connects their car to the charger, and the charging
              process begins.
            </li>
          </ol>
        </li>

        <li class="list-group-item font-primary">
          5. Stop Charging Session:
          <ol class="list-group">
            <li class="list-group-item">
              The customer can stop the charging session using Ryd's provided
              methods, or by stopping it in their car, at the charging port, or
              through a third-party app, if available
            </li>
            <li class="list-group-item">
              If the car is fully charged, the session will stop automatically
              In this case, stopping the session via Ryd is not necessary, but
              it can be triggered and will be handled properly by ryd.
            </li>
          </ol>
        </li>

        <li class="list-group-item font-primary">
          6. Receive Payment/Invoice Data:
          <ol class="list-group">
            <li class="list-group-item">
              In most cases, Ryd receives the payment/invoice data within a few
              minutes after the session stops, and this information is available
              in the "Get Order by ID" API response.
            </li>
            <li class="list-group-item">
              In this test scenario, the total cost is 35 EURO.
            </li>
            <li class="list-group-item">
              However, it is also common in EV charging for Ryd to receive this
              data from the respective
              <a href="/integration-guide/rydpay-apis/terms-and-definitions#cpo"
                >Charging Point Operator (CPO)</a
              >
              a few hours to a few days after the session has been completed.
            </li>
          </ol>
        </li>
      </ul>
      <br />

      <h5>
        <i class="icofont icofont-hand-right font-primary">&nbsp;</i>Expected
        Behavior:
      </h5>
      <ul class="list-group">
        <li class="list-group-item">
          1. The ryd partner's system processes the payment authorization
          correctly
        </li>
        <li class="list-group-item">
          2. The charging session is initiated and completed successfully
        </li>
        <li class="list-group-item">
          3. Appropriate status updates and messages are displayed to the
          customer.
        </li>
      </ul>
      <br />

      <h5>
        <i class="icofont icofont-hand-right font-primary">&nbsp;</i>Technical
        Verification Steps:
      </h5>
      <ol class="list-group" type="1">
        <li class="list-group-item">
          1. The <code>GET /v4/pois/{chargingStationId1}</code> API call returns
          HTTP 200, confirming the station details
        </li>
        <li class="list-group-item">
          2. The <code>POST /v4/order</code> API call creates an order and
          returns HTTP 201, confirming the order creation.
        </li>
        <li class="list-group-item">
          3. The <code>POST /v4/order/{orderId}/authorize</code> API call
          returns HTTP 200, confirming the payment authorization
        </li>
        <li class="list-group-item">
          4. The <code>PUT /v4/order/{orderId}/acquire</code> API call returns
          HTTP 200, confirming that an EV session has been successfully created
          with the respective
          <a href="/integration-guide/rydpay-apis/terms-and-definitions#cpo"
            >Charging Point Operator (CPO)</a
          >.
        </li>
        <li class="list-group-item">
          5. The <code>PUT /v4/order/{orderId}/acquire/stop</code> API call
          returns HTTP 200, confirming that the created EV session has been
          successfully stopped.
        </li>
        <li class="list-group-item">
          6. The <code>GET /v4/order/{orderId}</code> API call
          <ul class="list-group">
            <li class="list-group-item">returns an HTTP 200 status AND</li>
            <li class="list-group-item">
              includes the order state <code>PRODUCT_ACQUIRED</code> in the
              state history, along with the invoice data if available
            </li>
            <li class="list-group-item">
              DOESN'T include the order state <code>PROBLEM</code> in the state
              history
            </li>
            <li class="list-group-item">
              Here is a sample response from the
              <code>GET /v4/order/{orderId}</code>
              API.
            </li>
          </ul>
        </li>
      </ol>
      <br />
    </div>
  </div>

  <!-- TC 402 -->
  <div class="card" id="ts402">
    <div class="card-header bg-success">
      <h5>Scenario 402: Charging Session Overshoot</h5>
    </div>
    <div class="card-body">
      <h5>
        <i class="icofont icofont-hand-right font-success">&nbsp;</i>Description
      </h5>
      <ul class="list-group">
        <li class="list-group-item">
          In the context of <b>EV charging</b>, an overshoot occurs when the
          amount of energy delivered exceeds the initially authorized amount.
          Unlike fueling, EV charging stations do not have a mechanism to
          automatically stop charging at a pre-authorized monetary value. This
          results in the total costs beiging heigher than initially authorized.
        </li>
        <li class="list-group-item">
          In such cases, the overshoot should not affect the customer, and they
          must be allowed to leave the charging station without any issues. The
          respective order will be transferred into the manual resolution state,
          labeled as
          <code>PROBLEM</code>, and will need to be manually addressed by Ryd's
          payment operations services personnel.
        </li>
      </ul>
      <br />

      <h5>
        <i class="icofont icofont-hand-right font-success">&nbsp;</i>Steps:
      </h5>
      <ul class="list-group">
        <li class="list-group-item font-success">
          1. Select Charging Station:
          <ol class="list-group">
            <li class="list-group-item">
              The customer selects the charging station with the poiId
              <code>b989ce59-d976-411d-ba30-0d2e1f90baac</code> and selects the
              charging port <code>NLLMSE11700000*2</code>
            </li>
          </ol>
        </li>

        <li class="list-group-item font-success">
          2. Authorize Payment Amount:
          <ol class="list-group">
            <li class="list-group-item">
              Optionally, the customer can select a maximum amount to be
              authorized for the charging session.
            </li>
            <li class="list-group-item">
              Alternatively, the Ryd partner may always authorize a fixed amount
              without giving the customer an option to choose an amount.
            </li>
            <li class="list-group-item">
              In this test scenario, the amount is 40 EURO.
            </li>
            <li class="list-group-item">
              Important:
              <a
                href="/integration-guide/rydpay-apis/terms-and-definitions#overshoot"
                >Overshooting</a
              >
              often occurs in EV charging because charging stations cannot stop
              at a reserved amount like fueling pumps.
            </li>
          </ol>
        </li>

        <li class="list-group-item font-success">
          3. Authorize Payment:
          <ol class="list-group">
            <li class="list-group-item">
              The customer authorizes the payment.
            </li>
          </ol>
        </li>

        <li class="list-group-item font-success">
          4. Start Charging
          <ol class="list-group">
            <li class="list-group-item">
              The customer connects their car to the charger, and the charging
              process begins.
            </li>
          </ol>
        </li>

        <li class="list-group-item font-success">
          5. Stop Charging Session:
          <ol class="list-group">
            <li class="list-group-item">
              The customer can stop the charging session using Ryd's provided
              methods, or by stopping it in their car, at the charging port, or
              through a third-party app, if available
            </li>
            <li class="list-group-item">
              If the car is fully charged, the session will stop automatically
              In this case, stopping the session via Ryd is not necessary, but
              it can be triggered and will be handled properly by ryd.
            </li>
          </ol>
        </li>

        <li class="list-group-item font-success">
          6. Receive Payment/Invoice Data:
          <ol class="list-group">
            <li class="list-group-item">
              In most cases, Ryd receives the payment/invoice data within a few
              minutes after the session stops, and this information is available
              in the "Get Order by ID" API response.
            </li>
            <li class="list-group-item">
              In this test scenario, the total cost is 250 EURO that is higher
              than the pre-authorized amount of 40 EURO. It is an overshoot
              case.
            </li>
            <li class="list-group-item">
              It is up to the ryd partner to decide how to deal with this
              overshoot situation. In the case of Ryd's B2C business, Ryd tells
              the B2C end customer that the order is successful and they are
              allowed to drive away, but such an order has to be processed
              manually by Ryd's payment service personnel, sometimes Ryd's
              payment service personnel has to record the money or the different
              amount manually, depending on the payment method used.
            </li>
            <li class="list-group-item">
              However, it is also common in EV charging for Ryd to receive this
              data from the respective
              <a href="/integration-guide/rydpay-apis/terms-and-definitions#cpo"
                >Charging Point Operator (CPO)</a
              >
              a few hours to a few days after the session has been completed.
            </li>
          </ol>
        </li>
      </ul>
      <br />

      <h5>
        <i class="icofont icofont-hand-right font-success">&nbsp;</i>Expected
        Behavior:
      </h5>
      <ul class="list-group">
        <li class="list-group-item">
          1. The ryd partner's system processes the payment authorization
          correctly
        </li>
        <li class="list-group-item">
          2. The charging session is initiated and completed successfully
        </li>
        <li class="list-group-item">
          3. Appropriate status updates and messages are displayed to the
          customer.
        </li>
      </ul>
      <br />

      <h5>
        <i class="icofont icofont-hand-right font-success">&nbsp;</i>Technical
        Verification Steps:
      </h5>
      <ol class="list-group" type="1">
        <li class="list-group-item">
          1. The <code>GET /v4/pois/{chargingStationId1}</code> API call returns
          HTTP 200, confirming the station details
        </li>
        <li class="list-group-item">
          2. The <code>POST /v4/order</code> API call creates an order and
          returns HTTP 201, confirming the order creation.
        </li>
        <li class="list-group-item">
          3. The <code>POST /v4/order/{orderId}/authorize</code> API call
          returns HTTP 200, confirming the payment authorization
        </li>
        <li class="list-group-item">
          4. The <code>PUT /v4/order/{orderId}/acquire</code> API call returns
          HTTP 200, confirming that an EV session has been successfully created
          with the respective
          <a href="/integration-guide/rydpay-apis/terms-and-definitions#cpo"
            >Charging Point Operator (CPO)</a
          >.
        </li>
        <li class="list-group-item">
          5. The <code>PUT /v4/order/{orderId}/acquire/stop</code> API call
          returns HTTP 200, confirming that the created EV session has been
          successfully stopped.
        </li>
        <li class="list-group-item">
          6. The <code>GET /v4/order/{orderId}</code> API call
          <ul class="list-group">
            <li class="list-group-item">returns an HTTP 200 status AND</li>
            <li class="list-group-item">
              includes the order state <code>PRODUCT_ACQUIRED</code> in the
              state history, along with the invoice data if available
            </li>
            <li class="list-group-item">
              includes the order state <code>PROBLEM</code> in the state history
            </li>
            <li class="list-group-item">
              Here is a sample response from the
              <code>GET /v4/order/{orderId}</code>
              API.
            </li>
          </ul>
        </li>
      </ol>
      <br />
    </div>
  </div>

  <!-- TC 403 -->
  <div class="card" id="ts403">
    <div class="card-header bg-primary">
      <h5>Scenario 403: Charging Session Creation Failure</h5>
    </div>
    <div class="card-body">
      <h5>
        <i class="icofont icofont-hand-right font-primary">&nbsp;</i>Description
      </h5>
      <ul class="list-group">
        <li class="list-group-item">
          This scenario tests the behavior when an EV charging session cannot be
          created due to issues with the
          <a href="/integration-guide/rydpay-apis/terms-and-definitions#cpo"
            >Charging Point Operator (CPO)</a
          >. It examines how the system handles failures in initiating a
          charging session caused by external factors such as CPO connectivity
          problems, authorization failures, or other CPO-related issues.
        </li>
      </ul>
      <br />

      <h5>
        <i class="icofont icofont-hand-right font-primary">&nbsp;</i>Steps:
      </h5>
      <ul class="list-group">
        <li class="list-group-item font-primary">
          1. Select Charging Station:
          <ol class="list-group">
            <li class="list-group-item">
              The customer selects the charging station with the poiId
              <code>f1157103-63b5-4412-b7b0-4737dac45470</code> and selects the
              charging port <code>NLLMSE11711111*1</code>
            </li>
          </ol>
        </li>

        <li class="list-group-item font-primary">
          2. Authorize Payment Amount:
          <ol class="list-group">
            <li class="list-group-item">
              Optionally, the customer can select a maximum amount to be
              authorized for the charging session.
            </li>
            <li class="list-group-item">
              Alternatively, the Ryd partner may always authorize a fixed amount
              without giving the customer an option to choose an amount.
            </li>
            <li class="list-group-item">
              In this test scenario, the amount is 120 EURO.
            </li>
            <li class="list-group-item">
              Important:
              <a
                href="/integration-guide/rydpay-apis/terms-and-definitions#overshoot"
                >Overshooting</a
              >
              often occurs in EV charging because charging stations cannot stop
              at a reserved amount like fueling pumps.
            </li>
          </ol>
        </li>

        <li class="list-group-item font-primary">
          3. Authorize Payment:
          <ol class="list-group">
            <li class="list-group-item">
              The customer authorizes the payment.
            </li>
          </ol>
        </li>

        <li class="list-group-item font-primary">
          4. Start Charging:
          <ol class="list-group">
            <li class="list-group-item">
              The customer can not start charging because there is an issue with
              the respective
              <a href="/integration-guide/rydpay-apis/terms-and-definitions#cpo"
                >Charging Point Operator (CPO)</a
              >.
            </li>
          </ol>
        </li>
      </ul>
      <br />

      <h5>
        <i class="icofont icofont-hand-right font-primary">&nbsp;</i>Expected
        Behavior:
      </h5>
      <ul class="list-group">
        <li class="list-group-item">
          1. The ryd partner's system processes the payment authorization
          correctly
        </li>
        <li class="list-group-item">
          2. No charging session can not be created.
        </li>
      </ul>
      <br />

      <h5>
        <i class="icofont icofont-hand-right font-primary">&nbsp;</i>Technical
        Verification Steps:
      </h5>
      <ol class="list-group" type="1">
        <li class="list-group-item">
          1. The <code>GET /v4/pois/{chargingStationId1}</code> API call returns
          HTTP 200, confirming the station details
        </li>
        <li class="list-group-item">
          2. The <code>POST /v4/order</code> API call creates an order and
          returns HTTP 201, confirming the order creation.
        </li>
        <li class="list-group-item">
          3. The <code>POST /v4/order/{orderId}/authorize</code> API call
          returns HTTP 200, confirming the payment authorization
        </li>
        <li class="list-group-item">
          4. The <code>PUT /v4/order/{orderId}/acquire</code> API call returns
          HTTP <code>400</code>, confirming that no EV Charging session can be
          created with the respective
          <a href="/integration-guide/rydpay-apis/terms-and-definitions#cpo"
            >Charging Point Operator (CPO)</a
          >.
        </li>
      </ol>
      <br />
    </div>
  </div>
</template>

<script>
export default {
  name: "EVChargingTestCases",
};
</script>
