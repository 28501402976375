<template>
  <Breadcrumbs main="SDK Documentation" title="Overview"/>

  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">

        <div class="ribbon-wrapper card">
          <div class="card-body">
            <div class="ribbon ribbon-clip ribbon-secondary">Index of Content</div>
            <p>On this page, you will find essential information about ryd's SDK integration. The topics covered include:</p>

            <ul class="list-group list-group-flush">
              <li class="list-group-item">
                <a @click.prevent="scrollToCard('introduction')" class="text-primary text-decoration-none cursor-pointer">
                  <i class="icofont icofont-link font-primary">&nbsp;</i> Introduction & Benefits
                </a>
                Learn about ryd's SDK and its key advantages.
              </li>
              <li class="list-group-item">
                <a @click.prevent="scrollToCard('comparison')" class="text-primary text-decoration-none cursor-pointer">
                  <i class="icofont icofont-link font-primary">&nbsp;</i> SDK vs REST API
                </a>
                Compare SDK integration with direct REST API integration.
              </li>
              <li class="list-group-item">
                <a @click.prevent="scrollToCard('platform')" class="text-primary text-decoration-none cursor-pointer">
                  <i class="icofont icofont-link font-primary">&nbsp;</i> Platform Support
                </a>
                Explore supported platforms and implementation options.
              </li>
            </ul>
          </div>
        </div>

        <div class="card" id="introduction">
          <div class="card-header bg-primary d-flex justify-content-between align-items-center">
            <h5>Introduction & Benefits</h5>
            <button @click="scrollToTop" class="btn btn-light btn-sm text-white">
              <i class="icofont icofont-arrow-up"></i> Back to Top
            </button>
          </div>
          <div class="card-body">
            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>What is ryd SDK?</h5>
            <ul class="list-group">
              <li class="list-group-item">
                The ryd SDK is a comprehensive development kit that simplifies the integration of refueling, car wash, and EV charging services into your applications.
              </li>
              <li class="list-group-item">
                Built with Kotlin Multiplatform, it provides native support across multiple platforms while maintaining consistent behavior and reliability.
              </li>
            </ul>
            <br/>

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Key Benefits</h5>
            <ul class="list-group">
              <li class="list-group-item">
                <b>Simplified Integration</b>
                <ul class="list-group mt-2">
                  <li class="list-group-item">Single unified interface instead of managing multiple separate APIs</li>
                  <li class="list-group-item">Consistent behavior across all supported services (refueling, car wash, EV charging)</li>
                  <li class="list-group-item">Significantly reduced development time and resources needed</li>
                </ul>
              </li>
              <li class="list-group-item">
                <b>Built-in Intelligence</b>
                <ul class="list-group mt-2">
                  <li class="list-group-item">Sophisticated error handling with automatic retries and fallback mechanisms</li>
                  <li class="list-group-item">Comprehensive logging system for debugging and monitoring</li>
                  <li class="list-group-item">Implemented best practices for each service type</li>
                </ul>
              </li>
              <li class="list-group-item">
                <b>Reduced Maintenance</b>
                <ul class="list-group mt-2">
                  <li class="list-group-item">Updates to underlying APIs handled automatically within the SDK</li>
                  <li class="list-group-item">Automated version management and compatibility checks</li>
                  <li class="list-group-item">Lower risk of API breaking changes affecting your implementation</li>
                </ul>
              </li>
              <li class="list-group-item">
                <b>Enhanced Developer Experience</b>
                <ul class="list-group mt-2">
                  <li class="list-group-item">Clear, well-documented interfaces with code examples</li>
                  <li class="list-group-item">Platform-specific implementation guides and best practices</li>
                  <li class="list-group-item">Direct technical support from ryd's development team</li>
                </ul>
              </li>
              <li class="list-group-item">
                <b>Future-Proof Solution</b>
                <ul class="list-group mt-2">
                  <li class="list-group-item">New features and services easily added through SDK updates</li>
                  <li class="list-group-item">Backward compatibility maintained across versions</li>
                  <li class="list-group-item">Platform-specific optimizations handled automatically</li>
                </ul>
              </li>
            </ul>
            <br/>

            <div class="alert alert-primary outline fade show d-flex" role="alert">
              <i class="icofont icofont-info-circle mt-1"></i>
              <p class="mx-2">Detailed technical specifications and implementation guides are currently being developed and will be added soon.</p>
            </div>
          </div>
        </div>

        <div class="card" id="comparison">
          <div class="card-header bg-success d-flex justify-content-between align-items-center">
            <h5>SDK vs REST API</h5>
            <button @click="scrollToTop" class="btn btn-light btn-sm text-white">
              <i class="icofont icofont-arrow-up"></i> Back to Top
            </button>
          </div>
          <div class="card-body">
            <ul class="list-group">
              <li class="list-group-item font-success"><b>Integration Effort</b></li>
              <ul class="list-group">
                <li class="list-group-item"><b>SDK:</b> Simple integration with platform-specific packages</li>
                <li class="list-group-item"><b>REST API:</b> Requires implementing multiple API endpoints and handling</li>
              </ul>

              <li class="list-group-item font-success"><b>Development Time</b></li>
              <ul class="list-group">
                <li class="list-group-item"><b>SDK:</b> Faster with pre-built components ready to use</li>
                <li class="list-group-item"><b>REST API:</b> Longer development cycle, building from scratch</li>
              </ul>

              <li class="list-group-item font-success"><b>Error Handling</b></li>
              <ul class="list-group">
                <li class="list-group-item"><b>SDK:</b> Built-in, sophisticated error handling and retries</li>
                <li class="list-group-item"><b>REST API:</b> Custom error handling required for each endpoint</li>
              </ul>

              <li class="list-group-item font-success"><b>Maintenance</b></li>
              <ul class="list-group">
                <li class="list-group-item"><b>SDK:</b> Updates handled automatically through SDK versions</li>
                <li class="list-group-item"><b>REST API:</b> Manual maintenance and updates required</li>
              </ul>
            </ul>
          </div>
        </div>

        <div class="card" id="platform">
          <div class="card-header bg-primary d-flex justify-content-between align-items-center">
            <h5>Platform Support</h5>
            <button @click="scrollToTop" class="btn btn-light btn-sm text-white">
              <i class="icofont icofont-arrow-up"></i> Back to Top
            </button>
          </div>
          <div class="card-body">
            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Core Technology</h5>
            <ul class="list-group">
              <li class="list-group-item">
                <b>Kotlin Multiplatform Core:</b> The ryd SDK is built with Kotlin Multiplatform technology, enabling:
                <ul class="list-group mt-2">
                  <li class="list-group-item">Efficient code sharing across platforms</li>
                  <li class="list-group-item">Native performance optimization</li>
                  <li class="list-group-item">Platform-specific customization when needed</li>
                </ul>
              </li>
              <li class="list-group-item">
                <b>Supported Output Targets:</b>
                <ul class="list-group mt-2">
                  <li class="list-group-item">
                    <b>JVM/Android:</b> Java bytecode, Kotlin, and native Android development
                  </li>
                  <li class="list-group-item">
                    <b>Native (via Kotlin/Native):</b> iOS (Swift and Objective-C), macOS, Windows, Linux, WebAssembly
                  </li>
                  <li class="list-group-item">
                    <b>JavaScript/Web:</b> JavaScript and TypeScript, compatible with modern web frameworks
                  </li>
                </ul>
              </li>
            </ul>
            <br/>

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Native Mobile Applications</h5>
            <ul class="list-group">
              <li class="list-group-item">
                <b>Android Integration:</b>
                <ul class="list-group mt-2">
                  <li class="list-group-item">Direct compilation to Java bytecode and Kotlin</li>
                  <li class="list-group-item">Native Android SDK with platform-specific optimizations</li>
                  <li class="list-group-item">Full access to Android platform features</li>
                </ul>
              </li>
              <li class="list-group-item">
                <b>iOS Integration:</b>
                <ul class="list-group mt-2">
                  <li class="list-group-item">Native compilation via Kotlin/Native</li>
                  <li class="list-group-item">Swift and Objective-C compatibility</li>
                  <li class="list-group-item">Native iOS SDK with platform-specific features</li>
                </ul>
              </li>
            </ul>
            <br/>

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Cross-Platform Integration</h5>
            <ul class="list-group">
              <li class="list-group-item">
                <b>Flutter Support:</b>
                <ul class="list-group mt-2">
                  <li class="list-group-item">Platform-specific plugin bridging to native implementations</li>
                  <li class="list-group-item">Maintains native performance while providing Flutter compatibility</li>
                  <li class="list-group-item">Full feature parity through native bindings</li>
                </ul>
              </li>
              <li class="list-group-item">
                <b>React Native Support:</b>
                <ul class="list-group mt-2">
                  <li class="list-group-item">Native modules interfacing with Kotlin Multiplatform code</li>
                  <li class="list-group-item">JavaScript-friendly API interface</li>
                  <li class="list-group-item">Native performance through direct platform bindings</li>
                </ul>
              </li>
            </ul>
            <br/>

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Web Applications</h5>
            <ul class="list-group">
              <li class="list-group-item">
                <b>JavaScript/TypeScript Integration:</b>
                <ul class="list-group mt-2">
                  <li class="list-group-item">Direct compilation to JavaScript/TypeScript</li>
                  <li class="list-group-item">Support for modern web frameworks (React, Angular, Vue.js)</li>
                  <li class="list-group-item">Compatible with both browser and Node.js environments</li>
                </ul>
              </li>
              <li class="list-group-item">
                <b>WebAssembly Support:</b>
                <ul class="list-group mt-2">
                  <li class="list-group-item">High-performance web applications through WebAssembly compilation</li>
                  <li class="list-group-item">Seamless integration with web technologies</li>
                </ul>
              </li>
            </ul>
            <br/>

          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import {scrollToCard, scrollToRootHash, scrollToTop} from "@/utils/anchorUtils";

export default {
  name: "SdkOverview",
  keywords: ['SDK', 'Overview', 'Comparison', 'Platforms'],

  mounted() {
    this.scrollToRootHash();
  },
  methods: {
    scrollToCard,
    scrollToRootHash,
    scrollToTop
  },
  watch: {
    '$route.hash': function() {
      this.scrollToRootHash();
    }
  }
}
</script>