<template>
  <div class="search-links tab-pane fade" :class="{'show active': showtab == 'orderStateHistory' }"
       id="all-links"
       role="tabpanel" aria-labelledby="orderStateHistory">
    <!-- ... TABLE START... -->
    <div
        v-if="apiResponseApiOrderStateEvents && apiResponseApiOrderStateEvents.totalNumberOfResults && apiResponseApiOrderStateEvents.totalNumberOfResults > 0">
      <div class="order-history table-responsive">
        <table class="table table-bordernone table-striped display" id="basic-2">
          <thead class="bg-primary text-white">
          <tr>
            <th class="bg-primary" scope="col">Timestamp</th>
            <th class="bg-primary" scope="col">Event Type</th>
            <th class="bg-primary" scope="col">Event State Message</th>
            <th class="bg-primary" scope="col">POI Id</th>
            <th class="bg-primary" scope="col">User Group</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="message in apiResponseApiOrderStateEvents.messages" :key="message._id">
            <td>{{ message[0] }}</td>
            <td>{{ message[5] }}</td>
            <td>{{ message[2] }}</td>
            <td>{{ message[3] }}</td>
            <td>{{ message[4] }}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <!-- ... TABLE END... -->

      <!-- BUTTON START-->
      <br/>
      <div class="row" v-if="loggedInUserIsRyd">
        <div class="col">
          <div
              v-if="apiResponseApiOrderStateEvents && apiResponseApiOrderStateEvents.graylogQueryUrl">
            <a :href="apiResponseApiOrderStateEvents.graylogQueryUrl" target="_blank"
               class="btn btn-pill btn-success btn-lg" title="Go To Graylog Query">
              Go To Graylog Query
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- BUTTON END-->

    <div v-else>

      <!-- Loading Indicator START -->
      <div v-if="isLoadingApiOrderStateEvents" class="loading-indicator">
        <h6 class="sub-title mb-0 text-center">Loading ... Order State Events</h6>
        <div class="loader-box">
          <div class="loader-18"></div>
        </div>
      </div>
      <!-- Loading Indicator END -->

      <div class="text-center fw-bold fs-6 font-danger" v-if="apiResponseApiOrderStateEvents && apiResponseApiOrderStateEvents.errorMessage">
        {{this.apiResponseApiOrderStateEvents.errorMessage}}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'TabApiStateEvents',
  props: {
    apiResponseApiOrderStateEvents: {},
    showtab: {},
    isLoadingApiOrderStateEvents: {},
    loggedInUserIsRyd: {}
  }
}
</script>